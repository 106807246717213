/**
 * @generated SignedSource<<2f0914c95e9a83758ec43d61c8f3488d>>
 * @relayHash ae4acb191939cca088df4f95969cf420
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-misc/492.0.0-2024-12-13T19:52:32.511Z/homepageRootQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LocalRecentHistoryListInputType = {
  itemId?: string | null;
  timestamp?: string | null;
};
export type homepageRootQuery$variables = {
  abTestList?: ReadonlyArray<string | null> | null;
  activePrivateOfferCount: number;
  fetchBuyerActiveOffers: boolean;
  fetchInitialHomepage: boolean;
  hasUserId: boolean;
  homepageId: string;
  isClient: boolean;
  isDynamicCollectionVariant: boolean;
  isEditorsPickHpVariant: boolean;
  isMobile: boolean;
  isTrade: boolean;
  localRecentHistoryList?: ReadonlyArray<LocalRecentHistoryListInputType | null> | null;
  priceBookName: string;
  recentActivityDesignVariant?: string | null;
  userCountryCode: string;
  userId: string;
  userType: string;
};
export type homepageRootQuery$data = {
  readonly viewer: {
    readonly homepage: {
      readonly modules: ReadonlyArray<{
        readonly __id: string;
      } | null> | null;
      readonly " $fragmentSpreads": FragmentRefs<"getHomePageMetaContent_homepage">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"HpSharedLayout_viewer">;
  };
};
export type homepageRootQuery = {
  response: homepageRootQuery$data;
  variables: homepageRootQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "abTestList"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "activePrivateOfferCount"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchBuyerActiveOffers"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fetchInitialHomepage"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasUserId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "homepageId"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isClient"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isDynamicCollectionVariant"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isEditorsPickHpVariant"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isMobile"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isTrade"
},
v11 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "localRecentHistoryList"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "priceBookName"
},
v13 = {
  "defaultValue": "default",
  "kind": "LocalArgument",
  "name": "recentActivityDesignVariant"
},
v14 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userCountryCode"
},
v15 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v16 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userType"
},
v17 = [
  {
    "kind": "Variable",
    "name": "abTestList",
    "variableName": "abTestList"
  },
  {
    "kind": "Variable",
    "name": "rootId",
    "variableName": "homepageId"
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "HomepageMetadata",
  "kind": "LinkedField",
  "name": "metadata",
  "plural": false,
  "selections": [
    (v18/*: any*/),
    (v19/*: any*/)
  ],
  "storageKey": null
},
v21 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cmsDisplayTitle",
  "storageKey": null
},
v25 = [
  {
    "kind": "Variable",
    "name": "userType",
    "variableName": "userType"
  }
],
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "format",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cmsId",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFullBleedImage",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "path",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "concreteType": "LinkData",
  "kind": "LinkedField",
  "name": "linkData",
  "plural": false,
  "selections": [
    (v30/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isLinkable",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageCopy",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cta",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "backgroundColor",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "concreteType": "HeroBannerSettingsType",
  "kind": "LinkedField",
  "name": "heroBannerSettings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textAlignment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentColor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eyeBrowText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headerText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dekText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ctaStyle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentPosition",
      "storageKey": null
    },
    (v34/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bannerImageWidth",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageHeight",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageWidth",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobileImageHeight",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobileImageWidth",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mobileImageUrl",
  "storageKey": null
},
v41 = {
  "condition": "isMobile",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    (v38/*: any*/),
    (v39/*: any*/),
    (v40/*: any*/)
  ]
},
v42 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "items",
  "plural": true,
  "selections": [
    (v27/*: any*/),
    (v31/*: any*/),
    (v18/*: any*/),
    (v19/*: any*/),
    (v33/*: any*/),
    (v29/*: any*/),
    (v36/*: any*/),
    (v37/*: any*/)
  ],
  "storageKey": null
},
v43 = [
  (v23/*: any*/),
  (v24/*: any*/)
],
v44 = {
  "kind": "Variable",
  "name": "userId",
  "variableName": "userId"
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ecommerceTrackingParams",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contemporaryTrackingString",
  "storageKey": null
},
v48 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "browseUrl",
  "storageKey": null
},
v49 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categoryCode",
  "storageKey": null
},
v50 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v51 = [
  (v45/*: any*/),
  (v50/*: any*/)
],
v52 = {
  "alias": null,
  "args": null,
  "concreteType": "Seller",
  "kind": "LinkedField",
  "name": "seller",
  "plural": false,
  "selections": (v51/*: any*/),
  "storageKey": null
},
v53 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 1
  }
],
v54 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "smallPath",
  "storageKey": null
},
v55 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "localizedPdpUrl",
  "storageKey": null
},
v56 = [
  (v29/*: any*/),
  (v37/*: any*/),
  (v36/*: any*/)
],
v57 = {
  "alias": null,
  "args": null,
  "concreteType": "CollectionSpotlightImageType",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": (v56/*: any*/),
  "storageKey": null
},
v58 = {
  "alias": null,
  "args": null,
  "concreteType": "LinkData",
  "kind": "LinkedField",
  "name": "linkData",
  "plural": false,
  "selections": [
    (v30/*: any*/)
  ],
  "storageKey": null
},
v59 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v60 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemPricing",
  "kind": "LinkedField",
  "name": "pricing",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialAmount",
      "kind": "LinkedField",
      "name": "retailPrice",
      "plural": false,
      "selections": [
        (v59/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v61 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isMultiSku",
  "storageKey": null
},
v62 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vertical",
  "storageKey": null
},
v63 = {
  "alias": null,
  "args": null,
  "concreteType": "ShipmentQuote",
  "kind": "LinkedField",
  "name": "shipmentQuotes",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isComplimentary",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isCalculated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialAmount",
      "kind": "LinkedField",
      "name": "totalAmount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ConvertedAmounts",
          "kind": "LinkedField",
          "name": "convertedAmounts",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "USD",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShipmentServiceMethod",
      "kind": "LinkedField",
      "name": "serviceMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Region",
          "kind": "LinkedField",
          "name": "region",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "regionName",
              "storageKey": null
            },
            (v50/*: any*/)
          ],
          "storageKey": null
        },
        (v50/*: any*/)
      ],
      "storageKey": null
    },
    (v50/*: any*/)
  ],
  "storageKey": null
},
v64 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemClassification",
  "kind": "LinkedField",
  "name": "classification",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "creationDate",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v65 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v66 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemCreator",
  "kind": "LinkedField",
  "name": "creators",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attribution",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Creator",
      "kind": "LinkedField",
      "name": "creator",
      "plural": false,
      "selections": [
        (v65/*: any*/),
        (v50/*: any*/)
      ],
      "storageKey": null
    },
    (v50/*: any*/)
  ],
  "storageKey": null
},
v67 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "masterOrZoomPath",
  "storageKey": null
},
v68 = {
  "alias": "productPhotos",
  "args": (v53/*: any*/),
  "concreteType": "ItemPhoto",
  "kind": "LinkedField",
  "name": "photos",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "placeholder",
      "storageKey": null
    },
    (v54/*: any*/),
    (v67/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemPhotoVersion",
      "kind": "LinkedField",
      "name": "versions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "webPath",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v50/*: any*/)
  ],
  "storageKey": "photos(limit:1)"
},
v69 = [
  {
    "kind": "Literal",
    "name": "formatType",
    "value": "MOBILE"
  }
],
v70 = {
  "alias": null,
  "args": null,
  "concreteType": "QuickViewDisplayType",
  "kind": "LinkedField",
  "name": "quickViewDisplay",
  "plural": false,
  "selections": [
    (v18/*: any*/),
    {
      "alias": "mobileTitle",
      "args": (v69/*: any*/),
      "kind": "ScalarField",
      "name": "title",
      "storageKey": "title(formatType:\"MOBILE\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickViewParagraph",
      "kind": "LinkedField",
      "name": "paragraphs",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": "mobileText",
          "args": (v69/*: any*/),
          "kind": "ScalarField",
          "name": "text",
          "storageKey": "text(formatType:\"MOBILE\")"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickViewCreator",
      "kind": "LinkedField",
      "name": "creators",
      "plural": true,
      "selections": [
        (v65/*: any*/),
        (v31/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuickViewCategorySegments",
      "kind": "LinkedField",
      "name": "paragraphAttributeNames",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "period",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "origin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "style",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "periodPrefix",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v71 = {
  "alias": null,
  "args": null,
  "concreteType": "pdpMetaItems",
  "kind": "LinkedField",
  "name": "pdpMeta",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topQuery",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v72 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArt",
  "storageKey": null
},
v73 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isStorefrontOnly",
  "storageKey": null
},
v74 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isRingItem",
  "storageKey": null
},
v75 = {
  "alias": null,
  "args": null,
  "concreteType": "VariableAttribute",
  "kind": "LinkedField",
  "name": "variableAttributes",
  "plural": true,
  "selections": [
    (v45/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "VariableAttributeSortType",
      "kind": "LinkedField",
      "name": "variableAttributeData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SwatchSort",
          "kind": "LinkedField",
          "name": "swatchSort",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Swatch",
              "kind": "LinkedField",
              "name": "swatch",
              "plural": false,
              "selections": [
                (v45/*: any*/),
                (v65/*: any*/),
                (v29/*: any*/),
                (v50/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v50/*: any*/)
  ],
  "storageKey": null
},
v76 = {
  "kind": "Literal",
  "name": "page",
  "value": "searchAndBrowse"
},
v77 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v78 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quantityDisplay",
  "storageKey": null
},
v79 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percentageOff",
  "storageKey": null
},
v80 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "textType",
  "storageKey": null
},
v81 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amountType",
  "storageKey": null
},
v82 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ConvertedAmountList",
    "kind": "LinkedField",
    "name": "convertedAmountList",
    "plural": true,
    "selections": [
      (v59/*: any*/),
      (v77/*: any*/)
    ],
    "storageKey": null
  },
  (v78/*: any*/),
  (v79/*: any*/),
  (v80/*: any*/),
  (v81/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "showPriceLabel",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "showPriceVariability",
    "storageKey": null
  }
],
v83 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v84 = {
  "alias": null,
  "args": null,
  "concreteType": "ItemMeasurement",
  "kind": "LinkedField",
  "name": "measurement",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "addLabel",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "fields",
          "value": [
            "height",
            "width",
            "depth",
            "diameter",
            "length"
          ]
        },
        {
          "kind": "Literal",
          "name": "separator",
          "value": " "
        }
      ],
      "concreteType": "ItemDisplayMeasurementType",
      "kind": "LinkedField",
      "name": "display",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unit",
          "storageKey": null
        },
        (v83/*: any*/)
      ],
      "storageKey": "display(addLabel:true,fields:[\"height\",\"width\",\"depth\",\"diameter\",\"length\"],separator:\" \")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemSize",
      "kind": "LinkedField",
      "name": "size",
      "plural": false,
      "selections": [
        (v83/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v85 = {
  "kind": "Variable",
  "name": "priceBookName",
  "variableName": "priceBookName"
},
v86 = {
  "kind": "Literal",
  "name": "uriRef",
  "value": "/collections/editors-picks/"
},
v87 = {
  "kind": "Variable",
  "name": "userCountryCode",
  "variableName": "userCountryCode"
},
v88 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 5
  },
  (v85/*: any*/),
  (v86/*: any*/),
  (v87/*: any*/)
],
v89 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFurniture",
  "storageKey": null
},
v90 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "size",
      "value": "thumb"
    }
  ],
  "kind": "ScalarField",
  "name": "firstPhotoWebPath",
  "storageKey": "firstPhotoWebPath(size:\"thumb\")"
},
v91 = {
  "kind": "Variable",
  "name": "isTrade",
  "variableName": "isTrade"
},
v92 = {
  "alias": null,
  "args": [
    (v91/*: any*/),
    (v76/*: any*/),
    (v85/*: any*/)
  ],
  "concreteType": "ItemDisplayPriceType",
  "kind": "LinkedField",
  "name": "displayPrice",
  "plural": true,
  "selections": (v82/*: any*/),
  "storageKey": null
},
v93 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v94 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalResults",
  "storageKey": null
},
v95 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v96 = {
  "kind": "Literal",
  "name": "censored",
  "value": true
},
v97 = {
  "kind": "Literal",
  "name": "first",
  "value": 6
},
v98 = [
  (v96/*: any*/),
  (v97/*: any*/),
  (v85/*: any*/),
  {
    "kind": "Literal",
    "name": "uriRef",
    "value": "/collections/most-saved-items/?sort=trending"
  },
  (v87/*: any*/)
],
v99 = [
  {
    "kind": "Literal",
    "name": "portfolioType",
    "value": "HEART"
  },
  (v44/*: any*/)
],
v100 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ItemSearchItemsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Item",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v45/*: any*/),
          (v89/*: any*/),
          (v90/*: any*/),
          (v18/*: any*/),
          (v46/*: any*/),
          (v48/*: any*/),
          (v49/*: any*/),
          (v71/*: any*/),
          (v55/*: any*/),
          (v64/*: any*/),
          (v66/*: any*/),
          (v72/*: any*/),
          (v31/*: any*/),
          (v62/*: any*/),
          (v63/*: any*/),
          (v73/*: any*/),
          (v61/*: any*/),
          (v74/*: any*/),
          (v75/*: any*/),
          (v92/*: any*/),
          (v70/*: any*/),
          (v84/*: any*/),
          (v68/*: any*/),
          (v50/*: any*/),
          (v22/*: any*/),
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "heartPortfolioItems",
                "args": (v99/*: any*/),
                "concreteType": "PortfolioItemV2",
                "kind": "LinkedField",
                "name": "portfolioItems",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "portfolioId",
                    "storageKey": null
                  },
                  (v50/*: any*/)
                ],
                "storageKey": null
              },
              {
                "condition": "hasUserId",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": "heartPortfolioItems",
                    "args": (v99/*: any*/),
                    "concreteType": "PortfolioItemV2",
                    "kind": "LinkedField",
                    "name": "portfolioItems",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "portfolioItemId",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "folderPortfolioItems",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "portfolioType",
                        "value": "FOLDER"
                      },
                      (v44/*: any*/)
                    ],
                    "concreteType": "PortfolioItemV2",
                    "kind": "LinkedField",
                    "name": "portfolioItems",
                    "plural": true,
                    "selections": [
                      (v22/*: any*/),
                      (v50/*: any*/)
                    ],
                    "storageKey": null
                  }
                ]
              }
            ]
          }
        ],
        "storageKey": null
      },
      (v93/*: any*/)
    ],
    "storageKey": null
  },
  (v94/*: any*/),
  (v95/*: any*/)
],
v101 = [
  (v96/*: any*/),
  (v97/*: any*/),
  (v85/*: any*/),
  {
    "kind": "Literal",
    "name": "uriRef",
    "value": "/collections/excellent-values/?sort=trending"
  },
  (v87/*: any*/)
],
v102 = [
  (v96/*: any*/),
  (v97/*: any*/),
  (v85/*: any*/),
  {
    "kind": "Literal",
    "name": "uriRef",
    "value": "/collections/best-sellers/?sort=trending"
  },
  (v87/*: any*/)
],
v103 = [
  (v45/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "canShowSellerName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "canShowFolder",
    "storageKey": null
  }
],
v104 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v105 = [
  (v77/*: any*/),
  (v59/*: any*/)
],
v106 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "buyerId",
      "variableName": "userId"
    },
    (v91/*: any*/),
    (v85/*: any*/),
    (v87/*: any*/)
  ],
  "concreteType": "ItemDisplayPriceType",
  "kind": "LinkedField",
  "name": "displayPrice",
  "plural": true,
  "selections": [
    (v80/*: any*/),
    (v81/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ConvertedAmountList",
      "kind": "LinkedField",
      "name": "originalConvertedAmountList",
      "plural": true,
      "selections": (v105/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConvertedAmountList",
      "kind": "LinkedField",
      "name": "convertedAmountList",
      "plural": true,
      "selections": (v105/*: any*/),
      "storageKey": null
    },
    (v79/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ConvertedAmountList",
      "kind": "LinkedField",
      "name": "privateOfferConvertedAmountList",
      "plural": true,
      "selections": (v105/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "privateOfferPercentageOff",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "offerExpirationDate",
      "storageKey": null
    },
    (v78/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isVatIncluded",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priceBookName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/),
      (v14/*: any*/),
      (v15/*: any*/),
      (v16/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "homepageRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "HpSharedLayout_viewer"
          },
          {
            "alias": null,
            "args": (v17/*: any*/),
            "concreteType": "Homepage",
            "kind": "LinkedField",
            "name": "homepage",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "getHomePageMetaContent_homepage",
                "selections": [
                  (v20/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "modules",
                "plural": true,
                "selections": [
                  (v21/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v9/*: any*/),
      (v5/*: any*/),
      (v15/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v11/*: any*/),
      (v13/*: any*/),
      (v16/*: any*/),
      (v0/*: any*/),
      (v14/*: any*/),
      (v10/*: any*/),
      (v12/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/),
      (v8/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "homepageRootQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v17/*: any*/),
            "concreteType": "Homepage",
            "kind": "LinkedField",
            "name": "homepage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "modules",
                "plural": true,
                "selections": [
                  (v22/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v23/*: any*/)
                    ],
                    "type": "SkinnyBannerModule",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v23/*: any*/),
                      (v24/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "carouselSpeed",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v25/*: any*/),
                        "concreteType": "TopHeroBannerMessages",
                        "kind": "LinkedField",
                        "name": "messages",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TopHeroBannerItem",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": [
                              (v26/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "videoAutoplay",
                                "storageKey": null
                              },
                              (v27/*: any*/),
                              (v28/*: any*/),
                              (v29/*: any*/),
                              (v31/*: any*/),
                              (v32/*: any*/),
                              (v33/*: any*/),
                              (v35/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "videoUrl",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "mediaFormat",
                                "storageKey": null
                              },
                              {
                                "condition": "isMobile",
                                "kind": "Condition",
                                "passingValue": false,
                                "selections": [
                                  (v36/*: any*/),
                                  (v37/*: any*/)
                                ]
                              },
                              (v41/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "TopHeroBannerModule",
                    "abstractKey": null
                  },
                  {
                    "condition": "fetchInitialHomepage",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v23/*: any*/),
                          {
                            "condition": "isEditorsPickHpVariant",
                            "kind": "Condition",
                            "passingValue": false,
                            "selections": [
                              (v24/*: any*/)
                            ]
                          },
                          (v18/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "viewMoreLink",
                            "storageKey": null
                          }
                        ],
                        "type": "HeroModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v23/*: any*/),
                          (v18/*: any*/),
                          (v24/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": [
                              (v27/*: any*/),
                              (v18/*: any*/),
                              (v29/*: any*/),
                              (v31/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "CollectionsModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v23/*: any*/),
                          (v24/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FeaturedItem",
                            "kind": "LinkedField",
                            "name": "primaryItem",
                            "plural": false,
                            "selections": [
                              (v33/*: any*/),
                              (v29/*: any*/),
                              (v36/*: any*/),
                              (v37/*: any*/),
                              (v41/*: any*/),
                              (v18/*: any*/),
                              (v31/*: any*/),
                              (v34/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FeaturedItem",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": [
                              (v27/*: any*/),
                              (v33/*: any*/),
                              (v29/*: any*/),
                              (v18/*: any*/),
                              (v31/*: any*/),
                              (v34/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "textColor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "FeaturedModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v23/*: any*/),
                          (v24/*: any*/),
                          {
                            "alias": null,
                            "args": (v25/*: any*/),
                            "concreteType": "EditorialItem",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": [
                              (v27/*: any*/),
                              (v31/*: any*/),
                              (v18/*: any*/),
                              (v19/*: any*/),
                              (v33/*: any*/),
                              (v29/*: any*/),
                              (v37/*: any*/),
                              (v36/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "EditorialModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v23/*: any*/),
                          (v18/*: any*/),
                          (v24/*: any*/),
                          (v42/*: any*/)
                        ],
                        "type": "ShopByModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v23/*: any*/),
                          (v18/*: any*/),
                          (v24/*: any*/)
                        ],
                        "type": "ArrivalsModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v43/*: any*/),
                        "type": "RecentlyViewedModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v23/*: any*/),
                          (v24/*: any*/),
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Variable",
                                "name": "localRecentHistoryList",
                                "variableName": "localRecentHistoryList"
                              },
                              {
                                "kind": "Variable",
                                "name": "recentActivityDesignVariant",
                                "variableName": "recentActivityDesignVariant"
                              },
                              (v44/*: any*/)
                            ],
                            "concreteType": "RecentActivityItemsWithMeta",
                            "kind": "LinkedField",
                            "name": "recentActivityItemsWithMeta",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "itemsPerPage",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "RecentActivityItem",
                                "kind": "LinkedField",
                                "name": "items",
                                "plural": true,
                                "selections": [
                                  (v23/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Item",
                                    "kind": "LinkedField",
                                    "name": "serviceItem",
                                    "plural": false,
                                    "selections": [
                                      (v45/*: any*/),
                                      (v46/*: any*/),
                                      (v47/*: any*/),
                                      (v18/*: any*/),
                                      (v48/*: any*/),
                                      (v49/*: any*/),
                                      (v52/*: any*/),
                                      {
                                        "alias": null,
                                        "args": (v53/*: any*/),
                                        "concreteType": "ItemPhoto",
                                        "kind": "LinkedField",
                                        "name": "photos",
                                        "plural": true,
                                        "selections": [
                                          (v54/*: any*/),
                                          (v50/*: any*/)
                                        ],
                                        "storageKey": "photos(limit:1)"
                                      },
                                      (v55/*: any*/),
                                      (v50/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "RecentActivityModule",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v43/*: any*/),
                        "type": "RecommendedItemsModule",
                        "abstractKey": null
                      }
                    ]
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "index",
                        "storageKey": null
                      },
                      (v23/*: any*/),
                      {
                        "alias": null,
                        "args": (v25/*: any*/),
                        "concreteType": "PromoModuleMessages",
                        "kind": "LinkedField",
                        "name": "messages",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PromoItem",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": [
                              (v22/*: any*/),
                              (v27/*: any*/),
                              (v31/*: any*/),
                              (v32/*: any*/),
                              {
                                "condition": "isMobile",
                                "kind": "Condition",
                                "passingValue": false,
                                "selections": [
                                  (v29/*: any*/),
                                  (v36/*: any*/),
                                  (v37/*: any*/)
                                ]
                              },
                              {
                                "condition": "isMobile",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  (v40/*: any*/),
                                  (v38/*: any*/),
                                  (v39/*: any*/)
                                ]
                              },
                              (v33/*: any*/),
                              (v35/*: any*/),
                              (v28/*: any*/),
                              (v26/*: any*/),
                              (v18/*: any*/),
                              (v19/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v18/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v24/*: any*/)
                    ],
                    "type": "PromoModule",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v23/*: any*/),
                      {
                        "condition": "isDynamicCollectionVariant",
                        "kind": "Condition",
                        "passingValue": false,
                        "selections": [
                          (v18/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "subtitle",
                            "storageKey": null
                          },
                          (v24/*: any*/),
                          (v42/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Location",
                            "kind": "LinkedField",
                            "name": "location",
                            "plural": false,
                            "selections": [
                              (v18/*: any*/),
                              (v19/*: any*/),
                              (v29/*: any*/),
                              (v36/*: any*/),
                              (v37/*: any*/),
                              (v34/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "LocationItem",
                                "kind": "LinkedField",
                                "name": "items",
                                "plural": true,
                                "selections": [
                                  (v27/*: any*/),
                                  (v18/*: any*/),
                                  (v31/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ]
                      }
                    ],
                    "type": "LocationsModule",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v23/*: any*/),
                      (v24/*: any*/),
                      {
                        "alias": null,
                        "args": (v25/*: any*/),
                        "concreteType": "CollectionSpotlightItemMessages",
                        "kind": "LinkedField",
                        "name": "messages",
                        "plural": false,
                        "selections": [
                          (v18/*: any*/),
                          (v19/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isCollection",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "videoEmbedId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "linkText",
                            "storageKey": null
                          },
                          (v33/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CollectionSpotlightImageType",
                            "kind": "LinkedField",
                            "name": "collectionImage",
                            "plural": false,
                            "selections": (v56/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CollectionSpotlightImageType",
                            "kind": "LinkedField",
                            "name": "collectionPhoneImage",
                            "plural": false,
                            "selections": (v56/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CollectionSpotlightImageType",
                            "kind": "LinkedField",
                            "name": "profileImage",
                            "plural": false,
                            "selections": (v56/*: any*/),
                            "storageKey": null
                          },
                          (v57/*: any*/),
                          (v31/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "CollectionSpotlightItem",
                            "kind": "LinkedField",
                            "name": "items",
                            "plural": true,
                            "selections": [
                              (v18/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "itemId",
                                "storageKey": null
                              },
                              (v47/*: any*/),
                              (v31/*: any*/),
                              (v57/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "CollectionSpotlightModule",
                    "abstractKey": null
                  },
                  (v21/*: any*/)
                ],
                "storageKey": null
              },
              (v20/*: any*/),
              {
                "condition": "hasUserId",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 3
                      },
                      {
                        "kind": "Literal",
                        "name": "numItems",
                        "value": 3
                      },
                      (v44/*: any*/)
                    ],
                    "concreteType": "PersonalizationModuleConnection",
                    "kind": "LinkedField",
                    "name": "personalization",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PersonalizationModuleEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PersonalizationModule",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v58/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "categoryTitle",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "variant",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Item",
                                "kind": "LinkedField",
                                "name": "categoryMainItem",
                                "plural": false,
                                "selections": [
                                  (v45/*: any*/),
                                  (v47/*: any*/),
                                  (v52/*: any*/),
                                  (v60/*: any*/),
                                  (v50/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Item",
                                "kind": "LinkedField",
                                "name": "categoryItems",
                                "plural": true,
                                "selections": [
                                  (v55/*: any*/),
                                  (v45/*: any*/),
                                  (v47/*: any*/),
                                  (v52/*: any*/),
                                  (v60/*: any*/),
                                  (v61/*: any*/),
                                  (v18/*: any*/),
                                  (v62/*: any*/),
                                  (v63/*: any*/),
                                  (v64/*: any*/),
                                  (v66/*: any*/),
                                  (v68/*: any*/),
                                  (v58/*: any*/),
                                  (v70/*: any*/),
                                  (v50/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          (v50/*: any*/),
          (v22/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "pageSize",
                "value": 18
              },
              {
                "kind": "Literal",
                "name": "recentlyViewedIds",
                "value": []
              },
              {
                "kind": "Literal",
                "name": "returnAmount",
                "value": 15
              },
              {
                "kind": "Literal",
                "name": "userIds",
                "value": []
              }
            ],
            "concreteType": "Item",
            "kind": "LinkedField",
            "name": "recommendedItems",
            "plural": true,
            "selections": [
              (v45/*: any*/),
              (v47/*: any*/),
              (v18/*: any*/),
              (v48/*: any*/),
              (v49/*: any*/),
              (v52/*: any*/),
              (v46/*: any*/),
              (v71/*: any*/),
              (v55/*: any*/),
              (v64/*: any*/),
              (v66/*: any*/),
              (v72/*: any*/),
              (v31/*: any*/),
              (v73/*: any*/),
              (v61/*: any*/),
              (v74/*: any*/),
              (v75/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "isTrade",
                    "value": false
                  },
                  (v76/*: any*/)
                ],
                "concreteType": "ItemDisplayPriceType",
                "kind": "LinkedField",
                "name": "displayPrice",
                "plural": true,
                "selections": (v82/*: any*/),
                "storageKey": "displayPrice(isTrade:false,page:\"searchAndBrowse\")"
              },
              (v70/*: any*/),
              (v84/*: any*/),
              (v68/*: any*/),
              (v50/*: any*/)
            ],
            "storageKey": "recommendedItems(pageSize:18,recentlyViewedIds:[],returnAmount:15,userIds:[])"
          },
          {
            "condition": "isClient",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "condition": "isEditorsPickHpVariant",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "alias": "editorsPicksItemsControl",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 8
                      },
                      (v85/*: any*/),
                      (v86/*: any*/),
                      (v87/*: any*/)
                    ],
                    "concreteType": "ItemSearchItemsConnection",
                    "kind": "LinkedField",
                    "name": "itemSearchItems",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ItemSearchItemsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Item",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v45/*: any*/),
                              (v55/*: any*/),
                              (v18/*: any*/),
                              (v47/*: any*/),
                              {
                                "alias": "smallFirstPhotoWebPath",
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "size",
                                    "value": "small"
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "firstPhotoWebPath",
                                "storageKey": "firstPhotoWebPath(size:\"small\")"
                              },
                              (v50/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              },
              {
                "condition": "isEditorsPickHpVariant",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": "editorsPicksItemsVariant",
                    "args": (v88/*: any*/),
                    "concreteType": "ItemSearchItemsConnection",
                    "kind": "LinkedField",
                    "name": "itemSearchItems",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ItemSearchItemsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Item",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v45/*: any*/),
                              (v89/*: any*/),
                              (v90/*: any*/),
                              (v18/*: any*/),
                              (v46/*: any*/),
                              (v48/*: any*/),
                              (v49/*: any*/),
                              (v71/*: any*/),
                              (v55/*: any*/),
                              (v64/*: any*/),
                              (v66/*: any*/),
                              (v72/*: any*/),
                              (v31/*: any*/),
                              (v62/*: any*/),
                              (v63/*: any*/),
                              (v73/*: any*/),
                              (v61/*: any*/),
                              (v74/*: any*/),
                              (v75/*: any*/),
                              (v92/*: any*/),
                              (v70/*: any*/),
                              (v84/*: any*/),
                              (v68/*: any*/),
                              (v50/*: any*/),
                              (v22/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v93/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v94/*: any*/),
                      (v95/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": "editorsPicksItemsVariant",
                    "args": (v88/*: any*/),
                    "filters": [],
                    "handle": "connection",
                    "key": "HpSharedHeroModuleVariant_editorsPicksItemsVariant",
                    "kind": "LinkedHandle",
                    "name": "itemSearchItems"
                  }
                ]
              }
            ]
          },
          {
            "condition": "isDynamicCollectionVariant",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "mostSavedItems",
                "args": (v98/*: any*/),
                "concreteType": "ItemSearchItemsConnection",
                "kind": "LinkedField",
                "name": "itemSearchItems",
                "plural": false,
                "selections": (v100/*: any*/),
                "storageKey": null
              },
              {
                "alias": "mostSavedItems",
                "args": (v98/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "HpSharedMostSavedItemsCarousel_mostSavedItems",
                "kind": "LinkedHandle",
                "name": "itemSearchItems"
              },
              {
                "alias": "excellentValuesItems",
                "args": (v101/*: any*/),
                "concreteType": "ItemSearchItemsConnection",
                "kind": "LinkedField",
                "name": "itemSearchItems",
                "plural": false,
                "selections": (v100/*: any*/),
                "storageKey": null
              },
              {
                "alias": "excellentValuesItems",
                "args": (v101/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "HpSharedExcellentValuesCarousel_excellentValuesItems",
                "kind": "LinkedHandle",
                "name": "itemSearchItems"
              },
              {
                "alias": "bestsellingDesigns",
                "args": (v102/*: any*/),
                "concreteType": "ItemSearchItemsConnection",
                "kind": "LinkedField",
                "name": "itemSearchItems",
                "plural": false,
                "selections": (v100/*: any*/),
                "storageKey": null
              },
              {
                "alias": "bestsellingDesigns",
                "args": (v102/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "HpSharedBestsellingDesignsCarousel_bestsellingDesigns",
                "kind": "LinkedHandle",
                "name": "itemSearchItems"
              },
              {
                "condition": "hasUserId",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "condition": "hasUserId",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": "userPortfolios",
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 1
                          },
                          {
                            "kind": "Literal",
                            "name": "portfolioTypes",
                            "value": [
                              "HEART"
                            ]
                          },
                          {
                            "items": [
                              {
                                "kind": "Variable",
                                "name": "userIds.0",
                                "variableName": "userId"
                              }
                            ],
                            "kind": "ListValue",
                            "name": "userIds"
                          }
                        ],
                        "concreteType": "PortfoliosConnection",
                        "kind": "LinkedField",
                        "name": "portfolios",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PortfoliosEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PortfolioV2",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": (v51/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            "condition": "hasUserId",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  (v44/*: any*/)
                ],
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": "hasLoggedInWithApp",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "deviceType",
                        "value": "IOS_APP"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "hasLoggedInWithDeviceType",
                    "storageKey": "hasLoggedInWithDeviceType(deviceType:\"IOS_APP\")"
                  },
                  {
                    "condition": "isEditorsPickHpVariant",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": (v103/*: any*/)
                  },
                  {
                    "condition": "isDynamicCollectionVariant",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": (v103/*: any*/)
                  },
                  (v50/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "fetchBuyerActiveOffers",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "first",
                    "variableName": "activePrivateOfferCount"
                  },
                  {
                    "kind": "Literal",
                    "name": "page",
                    "value": 0
                  },
                  (v44/*: any*/)
                ],
                "concreteType": "BuyerActivePrivateOffersConnection",
                "kind": "LinkedField",
                "name": "buyerActivePrivateOffers",
                "plural": false,
                "selections": [
                  (v94/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BuyerActivePrivateOffersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      (v22/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ActivePrivateOffer",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "selectedSkuId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Item",
                            "kind": "LinkedField",
                            "name": "item",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "VideoVimeo",
                                "kind": "LinkedField",
                                "name": "videoVimeo",
                                "plural": false,
                                "selections": [
                                  (v104/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "vimeoId",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ItemReviewDocumentList",
                                "kind": "LinkedField",
                                "name": "reviews",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ItemReview",
                                    "kind": "LinkedField",
                                    "name": "videoReview",
                                    "plural": false,
                                    "selections": [
                                      (v104/*: any*/),
                                      (v50/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v61/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ItemPhoto",
                                "kind": "LinkedField",
                                "name": "sortedPhotos",
                                "plural": true,
                                "selections": [
                                  (v22/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ImageSkuXref",
                                    "kind": "LinkedField",
                                    "name": "imageSkuXrefs",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "skuId",
                                        "storageKey": null
                                      },
                                      (v50/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v67/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "alt",
                                    "storageKey": null
                                  },
                                  (v50/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v50/*: any*/),
                              (v55/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Hold",
                                "kind": "LinkedField",
                                "name": "activeHold",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "displayTimeToExpire",
                                    "storageKey": null
                                  },
                                  (v50/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ItemSkuType",
                                "kind": "LinkedField",
                                "name": "skus",
                                "plural": true,
                                "selections": [
                                  (v45/*: any*/),
                                  (v106/*: any*/),
                                  (v50/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v106/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v50/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-misc/492.0.0-2024-12-13T19:52:32.511Z/homepageRootQuery",
    "metadata": {},
    "name": "homepageRootQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7824acb0aad7fb1aed8615740e93d473";

export default node;
